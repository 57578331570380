<template>
  <div id="business-canvas">
    <div id="steps" class="d-inline-flex w-100 justify-content-end mb-1">
      <div id="next-steps">
        <span class="font-medium-1 mr-1">
          Next steps
          <feather-icon
            icon="ChevronsRightIcon"
          />
        </span>
        <b-button class="ml-50" variant="outline-primary" size="sm">
          <router-link :to="{ name: 'model_capabilities' }">
            Capability-Based Planning
          </router-link>
        </b-button>
        <b-button class="ml-50" variant="outline-primary" size="sm">
          <router-link :to="{ name: 'model_ideas' }">
            Gather Ideas
          </router-link>
        </b-button>
        <b-button class="ml-50" variant="outline-primary" size="sm">
          <router-link :to="{ name: 'domain_analysis' }">
            Define Functions
          </router-link>
        </b-button>
      </div>
    </div>

    <b-card id="objectives" no-body>
      <b-card-body>
        <table-header
          label="Objectives, Benefits & Key Results/KPIs"
          modal="create-objective-modal"
        >
          <!--<template v-slot:additionalButtons>-->
          <!--  <b-button variant="flat-primary" size="sm" class="float-right" @click="$bvModal.show('manage-kpis-modal')">-->
          <!--    Manage KPIs-->
          <!--  </b-button>-->
          <!--  <b-button variant="flat-primary" size="sm" class="float-right" @click="$bvModal.show('manage-benefits-modal')">-->
          <!--    Manage Benefits-->
          <!--  </b-button>-->
          <!--</template>-->
        </table-header>
      </b-card-body>

      <b-table striped hover :items="objectives" :fields="tblFieldsObjectives" :filter="criteria" :filter-function="filterTable" show-empty>
        <template v-slot:cell(obj)="data">
          <div v-if="data.item.description && data.item.description !== ''">
            <b>{{ data.item.name }}</b><br>
            {{ data.item.description }}
          </div>
          <div v-else>
            {{ data.item.name }}
          </div>
        </template>
        <template v-slot:cell(benefits_l)="data">
          <ul>
            <li v-for="bft in data.item.benefits" :key="bft.id">
              {{ bft.name }}
            </li>
          </ul>
        </template>
        <template v-slot:cell(kpi_l)="data">
          <ul>
            <li v-for="kpi in data.item.kpis" :key="kpi.id">
              {{ kpi.name }}
            </li>
          </ul>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button variant="flat-secondary" size="sm" class="btn-icon" @click="updateObjective(data)">
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button variant="flat-danger" size="sm" class="btn-icon" @click="deleteObjective(data)">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>

    <b-card id="stakeholders" no-body>
      <b-card-body>
        <table-header
          label="Stakeholders"
          modal="create-stakeholder-modal"
        />
      </b-card-body>

      <b-table
        striped
        hover
        show-empty
        :items="stakeholders"
        :fields="tblFieldsStakeholders"
        :filter="criteria"
        :filter-function="filterTable"
      >
        <template v-slot:cell(group)="data">
          <div v-if="data.item.description && data.item.description !== ''">
            <b>{{ data.item.name }}</b><br>
            {{ data.item.description }}
          </div>
          <div v-else>
            {{ data.item.name }}
          </div>
        </template>
        <template v-slot:cell(concerns)="data">
          {{ data.item.concerns }}
        </template>
        <template v-slot:cell(class)="data">
          {{ data.item.class }}
        </template>
        <template v-slot:cell(view_l)="data">
          <ul>
            <li v-for="v in data.item.views" :key="v">
              {{ v }}
            </li>
          </ul>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button variant="flat-secondary" size="sm" class="btn-icon" @click="updateStakeholder(data)">
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button variant="flat-danger" size="sm" class="btn-icon" @click="deleteStakeholder(data)">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>

    <div class="d-inline-flex w-100 justify-content-around">
      <b-card id="assumptions" no-body class="w-100">
      <b-card-body>
        <table-header
          label="Assumptions"
          modal="create-assumption-modal"
        />
      </b-card-body>

      <b-table striped hover :items="assumptions" :fields="tblFieldsAssumptions" show-empty>
        <template v-slot:cell(name)="data">
          <div v-if="data.item.description && data.item.description !== ''">
            <b>{{ data.item.name }}</b><br>
            {{ data.item.description }}
          </div>
          <div v-else>
            {{ data.item.name }}
          </div>
        </template>

        <template v-slot:cell(actions_a)="data">
          <b-button variant="flat-secondary" size="sm" class="btn-icon" @click="updateAssumption(data)">
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button variant="flat-danger" size="sm" class="btn-icon" @click="deleteAssumption(data)">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>

      <b-card id="dependencies" no-body class="w-100 mx-2">
        <b-card-body>
          <table-header
            label="Dependencies"
            modal="create-dependency-modal"
          />
        </b-card-body>

        <b-table striped hover :items="dependencies" :fields="tblFieldsDependencies" show-empty>
          <template v-slot:cell(name)="data">
            <div v-if="data.item.description && data.item.description !== ''">
              <b>{{ data.item.name }}</b><br>
              {{ data.item.description }}
            </div>
            <div v-else>
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:cell(actions_d)="data">
            <b-button variant="flat-secondary" size="sm" class="btn-icon" @click="updateDependency(data)">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button variant="flat-danger" size="sm" class="btn-icon" @click="deleteDependency(data)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </b-card>

      <b-card id="constraints" no-body class="w-100">
        <b-card-body>
          <table-header
            label="Constraints"
            modal="create-constraint-modal"
          />
        </b-card-body>

        <b-table striped hover :items="constraints" :fields="tblFieldsConstraints" show-empty>
          <template v-slot:cell(name)="data">
            <div v-if="data.item.description && data.item.description !== ''">
              <b>{{ data.item.name }}</b><br>
              {{ data.item.description }}
            </div>
            <div v-else>
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:cell(actions_c)="data">
            <b-button variant="flat-secondary" size="sm" class="btn-icon" @click="updateConstraint(data)">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button variant="flat-danger" size="sm" class="btn-icon" @click="deleteConstraint(data)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </div>

    <div id="modals">
      <!-- Manage Benefit & KPI Modals -->
      <ManageBenefitsModal />
      <ManageKPIsModal />

      <!-- Objective Modals -->
      <CreateObjectiveModal />
      <UpdateObjectiveModal />
      <DeleteObjectiveModal />

      <!-- Stakeholder Modals -->
      <CreateStakeholderModal />
      <UpdateStakeholderModal />
      <DeleteStakeholderModal />

      <!-- Assumption Modals -->
      <CreateAssumptionModal />
      <UpdateAssumptionModal />
      <DeleteAssumptionModal />

      <!-- Dependency Modals -->
      <CreateDependencyModal />
      <UpdateDependencyModal />
      <DeleteDependencyModal />

      <!-- Constraint Modals -->
      <CreateConstraintModal />
      <UpdateConstraintModal />
      <DeleteConstraintModal />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BButton, BTable,
} from 'bootstrap-vue'
import TableHeader from '@/components/BRSCanvas/TableHeader.vue'
import ManageBenefitsModal from '@/components/BRSCanvas/Modals/Manage/ManageBenefits.vue'
import ManageKPIsModal from '@/components/BRSCanvas/Modals/Manage/ManageKPIs.vue'
import CreateAssumptionModal from '@/components/BRSCanvas/Modals/Create/CreateAssumption.vue'
import CreateConstraintModal from '@/components/BRSCanvas/Modals/Create/CreateConstraint.vue'
import CreateDependencyModal from '@/components/BRSCanvas/Modals/Create/CreateDependency.vue'
import CreateObjectiveModal from '@/components/BRSCanvas/Modals/Create/CreateObjective.vue'
import CreateStakeholderModal from '@/components/BRSCanvas/Modals/Create/CreateStakeholder.vue'
import DeleteAssumptionModal from '@/components/BRSCanvas/Modals/Delete/DeleteAssumption.vue'
import DeleteConstraintModal from '@/components/BRSCanvas/Modals/Delete/DeleteConstraint.vue'
import DeleteDependencyModal from '@/components/BRSCanvas/Modals/Delete/DeleteDependency.vue'
import DeleteObjectiveModal from '@/components/BRSCanvas/Modals/Delete/DeleteObjective.vue'
import DeleteStakeholderModal from '@/components/BRSCanvas/Modals/Delete/DeleteStakeholder.vue'
import UpdateAssumptionModal from '@/components/BRSCanvas/Modals/Update/UpdateAssumption.vue'
import UpdateConstraintModal from '@/components/BRSCanvas/Modals/Update/UpdateConstraint.vue'
import UpdateDependencyModal from '@/components/BRSCanvas/Modals/Update/UpdateDependency.vue'
import UpdateObjectiveModal from '@/components/BRSCanvas/Modals/Update/UpdateObjective.vue'
import UpdateStakeholderModal from '@/components/BRSCanvas/Modals/Update/UpdateStakeholder.vue'

export default {
  components: {
    BButton,
    BTable,
    TableHeader,

    // Manage Benefit & KPI Modals
    ManageBenefitsModal,
    ManageKPIsModal,

    // Objectives Modals
    CreateObjectiveModal,
    UpdateObjectiveModal,
    DeleteObjectiveModal,

    // Stakeholder Modals
    CreateStakeholderModal,
    UpdateStakeholderModal,
    DeleteStakeholderModal,

    // Assumption Modals
    CreateAssumptionModal,
    UpdateAssumptionModal,
    DeleteAssumptionModal,

    // Dependency Modals
    CreateDependencyModal,
    UpdateDependencyModal,
    DeleteDependencyModal,

    // Constraint Modals
    CreateConstraintModal,
    UpdateConstraintModal,
    DeleteConstraintModal,
  },
  data: () => ({
    criteria: 'True',
    tblFieldsObjectives: [
      {
        key: 'obj',
        label: 'Objective',
        sortable: true,
      },
      {
        key: 'benefits_l',
        label: 'Benefits',
      },
      {
        key: 'kpi_l',
        label: 'Key Results/KPIs',
      },
      {
        key: 'actions',
        label: 'Actions',
        thStyle: {
          width: '9rem',
        },
      },
    ],
    tblFieldsStakeholders: [
      {
        key: 'group',
        label: 'Stakeholder Group',
        sortable: true,
      },
      {
        key: 'concerns',
        label: 'Key Concerns',
      },
      {
        key: 'class',
        label: 'Class',
      },
      {
        key: 'view_l',
        label: 'Relevant Viewpoints',
      },
      {
        key: 'actions',
        label: 'Actions',
        thStyle: {
          width: '9rem',
        },
      },
    ],
    tblFieldsAssumptions: [
      {
        key: 'name',
        label: 'Assumption',
        sortable: true,
      },
      {
        key: 'actions_a',
        label: 'Actions',
        thStyle: {
          width: '9rem',
        },
      },
    ],
    tblFieldsDependencies: [
      {
        key: 'name',
        label: 'Dependencies',
        sortable: true,
      },
      {
        key: 'actions_d',
        label: 'Actions',
        thStyle: {
          width: '9rem',
        },
      },
    ],
    tblFieldsConstraints: [
      {
        key: 'name',
        label: 'Project Constraint',
        sortable: true,
      },
      {
        key: 'actions_c',
        label: 'Actions',
        thStyle: {
          width: '9rem',
        },
      },
    ],
    selectedObjectiveId: '',
    selectedStakeholderId: '',
    selectedAssumptionId: '',
    selectedDependencyId: '',
    selectedConstraintId: '',
  }),
  computed: {
    ...mapState({
      objectives: state => state.canvas.objectives,
      stakeholders: state => state.canvas.stakeholders,
      assumptions: state => state.canvas.assumptions,
      dependencies: state => state.canvas.dependencies,
      constraints: state => state.canvas.constraints,
      props: state => state.model.lookup,
    }),
  },
  mounted() {
    this.fetchCanvas()
  },
  beforeDestroy() {
    this.$store.dispatch('canvas/clearCanvas')
  },
  methods: {
    fetchCanvas() {
      this.$store.dispatch('canvas/fetchCanvas')
    },
    filterTable(row, criteria) {
      return (row.abstract !== criteria)
    },

    updateAssumption(data) {
      this.$store
        .dispatch('canvas/selectAssumption', data.item.id)
        .then(() => this.$bvModal.show('update-assumption-modal'))
    },
    updateConstraint(data) {
      this.$store
        .dispatch('canvas/selectConstraint', data.item.id)
        .then(() => this.$bvModal.show('update-constraint-modal'))
    },
    updateDependency(data) {
      this.$store
        .dispatch('canvas/selectDependency', data.item.id)
        .then(() => this.$bvModal.show('update-dependency-modal'))
    },
    updateObjective(data) {
      this.$store
        .dispatch('canvas/selectObjective', data.item.id)
        .then(() => this.$bvModal.show('update-objective-modal'))
    },
    updateStakeholder(data) {
      this.$store
        .dispatch('canvas/selectStakeholder', data.item.id)
        .then(() => this.$bvModal.show('update-stakeholder-modal'))
    },

    deleteAssumption(data) {
      this.$store
        .dispatch('canvas/selectAssumption', data.item.id)
        .then(() => this.$bvModal.show('delete-assumption-modal'))
    },
    deleteConstraint(data) {
      this.$store
        .dispatch('canvas/selectConstraint', data.item.id)
        .then(() => this.$bvModal.show('delete-constraint-modal'))
    },
    deleteDependency(data) {
      this.$store
        .dispatch('canvas/selectDependency', data.item.id)
        .then(() => this.$bvModal.show('delete-dependency-modal'))
    },
    deleteObjective(data) {
      this.$store
        .dispatch('canvas/selectObjective', data.item.id)
        .then(() => this.$bvModal.show('delete-objective-modal'))
    },
    deleteStakeholder(data) {
      this.$store
        .dispatch('canvas/selectStakeholder', data.item.id)
        .then(() => this.$bvModal.show('delete-stakeholder-modal'))
    },
  },
}
</script>
