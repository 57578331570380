<template>
  <div>
    <b-modal
      id="create-objective-modal"
      title="Create Objective"
      size="xl"
      @show="show"
      @cancel="cancel"
    >
      <b-row>
        <b-col>
          <b-row class="mb-2">
            <b-col>
              <label for="txtObjectiveName">Objective</label>
              <b-form-textarea
                id="txtObjectiveName"
                v-model="localObjective.name"
                placeholder="What is the Objective?"
                rows="5"
              />
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <label for="txtObjectiveDetails">Detailed Description</label>
              <b-form-textarea
                id="txtObjectiveDetails"
                v-model="localObjective.description"
                placeholder="Describe the Objective or Outcome..."
                rows="6"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <!-- Benefits -->
          <div class="mb-2">
            <b-button
              v-b-tooltip.hover.left="'Associate Benefit entities with this Objective'"
              variant="flat-success"
              size="sm"
              class="mb-25"
              @click="$bvModal.show('associate-benefits-create-modal')"
            >
              <feather-icon icon="LinkIcon" />
            </b-button>
            <span class="font-medium-2">Associated Benefits</span>

            <b-table striped hover :items="tblDataBenefits" :fields="tblFieldsBenefits">
              <template v-slot:cell(benefit)="data">
                <div v-if="data.item.description && data.item.description !== ''">
                  <b>{{ data.item.name }}</b><br>
                  {{ data.item.description }}
                </div>
                <div v-else>
                  {{ data.item.name }}
                </div>
              </template>
            </b-table>
          </div>
          <!-- ./Benefits -->

          <!-- KPIs -->
          <div class="mb-2">
            <b-button
              v-b-tooltip.hover.left="'Associate Key Results/KPI entities with this Objective'"
              variant="flat-success"
              size="sm"
              class="mb-25"
              @click="$bvModal.show('associate-kpis-create-modal')"
            >
              <feather-icon icon="LinkIcon" />
            </b-button>
            <span class="font-medium-2">Associated Key Results/KPIs</span>

            <b-table striped hover :items="tblDataKPIs" :fields="tblFieldsKPIs">
              <template v-slot:cell(kpi)="data">
                <div v-if="data.item.description && data.item.description !== ''">
                  <b>{{ data.item.name }}</b><br>
                  {{ data.item.description }}
                </div>
                <div v-else>
                  {{ data.item.name }}
                </div>
              </template>
            </b-table>
          </div>
          <!-- ./KPIs -->
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading" @click="submit">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Creating...
          </span>

          <span v-else>
            Create Objective
          </span>
        </b-button>
      </template>
    </b-modal>

    <CreateAssociateBenefits @resolve="resolveBenefits" />
    <CreateAssociateKPIs @resolve="resolveKPIs" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BButton, VBTooltip } from 'bootstrap-vue'
import CreateAssociateBenefits from '@/components/BRSCanvas/Modals/Create/CreateAssociateBenefits.vue'
import CreateAssociateKPIs from '@/components/BRSCanvas/Modals/Create/CreateAssociateKPIs.vue'

export default {
  components: {
    BButton,
    CreateAssociateBenefits,
    CreateAssociateKPIs,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    localObjective: {
      name: '',
      description: '',
      benefits: [],
      kpis: [],
    },
    loading: false,
    tblFieldsBenefits: [
      {
        key: 'benefit',
        label: 'Benefit',
        sortable: true,
      },
    ],
    tblFieldsKPIs: [
      {
        key: 'kpi',
        label: 'Key Result/KPI',
        sortable: true,
      },
    ],
    tblDataBenefits: [],
    tblDataKPIs: [],
  }),
  computed: {
    ...mapState({
      selectedObjective: state => state.canvas.selectedObjective,
      benefits: state => state.canvas.benefits,
      kpis: state => state.canvas.kpis,
    }),
  },
  methods: {
    show() {
      this.$store.commit('canvas/SET_SELECTED_OBJECTIVE', this.localObjective)
      this.$store.dispatch('canvas/fetchBenefits')
      this.$store.dispatch('canvas/fetchKPIs')
    },
    cancel() {
      this.clearForm()
      this.$bvModal.hide('create-objective-modal')
    },
    clearForm() {
      this.localObjective = {
        name: '',
        description: '',
        benefits: [],
        kpis: [],
      }
      this.tblDataBenefits = []
      this.tblDataKPIs = []
    },
    submit() {
      this.loading = true
      this.$store.dispatch('canvas/createObjective', this.selectedObjective)
        .then(() => {
          this.loading = false
          this.clearForm()
          this.$bvModal.hide('create-objective-modal')
        })
    },
    resolveBenefits() {
      this.$store
        .dispatch('canvas/fetchBenefits')
        .then(() => {
          this.tblDataBenefits = []
          this.selectedObjective.benefits.forEach(elementA => {
            this.benefits.forEach(elementB => {
              if (elementA === elementB.id) {
                this.tblDataBenefits.push({ id: elementA, name: elementB.name })
              }
            })
          })
        })
    },
    resolveKPIs() {
      this.$store
        .dispatch('canvas/fetchKPIs')
        .then(() => {
          this.tblDataKPIs = []
          this.localObjective.kpis.forEach(elementA => {
            this.kpis.forEach(elementB => {
              if (elementA === elementB.id) {
                this.tblDataKPIs.push({ id: elementA, name: elementB.name })
              }
            })
          })
        })
    },
  },
}
</script>

<style lang="scss">
#create-objective-modal {
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}
</style>
