<template>
  <div>
    <b-modal
      id="create-dependency-modal"
      title="Create Dependency"
      size="lg"
      @hide="clear"
    >
      <b-row class="mb-2">
        <b-col>
          <label for="add_dependency_name">Title</label>
          <b-form-input
            id="add_dependency_name"
            v-model="newDependency.name"
            type="text"
            placeholder="What is the dependency?"
            required
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <label for="add_dependency_desc">Description</label>
          <b-form-textarea
            id="add_dependency_desc"
            v-model="newDependency.description"
            placeholder="Describe the details of the dependency..."
            rows="6"
            max-rows="6"
          />
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading" @click="addDependency">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Creating...
          </span>

          <span v-else>
            Create Dependency
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    newDependency: {
      name: '',
      description: '',
    },
    loading: false,
  }),
  methods: {
    addDependency() {
      this.loading = true
      this.$store
        .dispatch('canvas/createDependency', this.newDependency)
        .then(() => {
          this.loading = false
          this.$bvModal.hide('create-dependency-modal')
        })
    },
    clear() {
      this.loading = false
      this.newDependency = {
        name: '',
        description: '',
      }
    },
  },
}
</script>
