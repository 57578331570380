<template>
  <b-modal
    id="manage-kpis-modal"
    title="Manage Key Results/KPIs"
    size="md"
    ok-title="Done"
    ok-variant="outline-success"
    ok-only
    @ok="submit"
    @shown="shown"
  >
    Test
  </b-modal>
</template>

<script>
export default {
  name: 'ManageKPIs',
  methods: {
    shown() {
      console.log('shown')
    },
    submit() {
      console.log('submit')
    },
  },
}
</script>

<style scoped>

</style>
