<template>
  <b-modal
    id="delete-assumption-modal"
    title="Delete Assumption. Are you sure?"
    size="md"
    centered
    @ok="submit"
  >
    <p>
      Deleting the
      <span class="text-primary font-weight-bolder">{{ selectedAssumption.name }}</span>
      assumption cannot be undone.
    </p>
    <p class="mb-0 font-weight-bolder">
      Are you sure?
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="ok()">
        Yes, delete assumption
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectedAssumption: state => state.canvas.selectedAssumption,
    }),
  },
  methods: {
    submit(event) {
      event.preventDefault()
      this.$store
        .dispatch('canvas/deleteAssumption', this.selectedAssumption.id)
        .then(() => {
          this.$store.commit('canvas/CLEAR_SELECTED_ASSUMPTION')
          this.$bvModal.hide('delete-assumption-modal')
        })
    },
  },
}
</script>
