<template>
  <b-modal
    id="associate-kpis-create-modal"
    title="Associate Key Results/KPIs"
    size="lg"
    @show="onShow"
  >
    <b-row>
      <b-col cols="9">
        <label>
          Create new Key Result/KPI
        </label>
        <b-textarea v-model="newKPI" rows="2" />
      </b-col>
      <b-col>
        <b-button variant="outline-primary" style="margin-top: 2.25rem" block @click="create">
          Create & Associate
        </b-button>
      </b-col>
    </b-row>

    <hr class="my-2">

    <!-- Available Items -->
    <b-row>
      <b-col>
        <label for="allItems">
          <b-badge class="bg-info">{{ allItems.length }}</b-badge> :
          Available Key Results/KPI{{ allItems | handlePluralSuffix }}
        </label>
        <div v-if="loadingAll" class="text-center">
          <b-spinner class="mt-3" variant="primary" label="Loading..." />
        </div>
        <b-form-select
          v-else
          id="allItems"
          v-model="selectedAllItems"
          :options="allItems"
          :select-size="6"
          multiple
        />
      </b-col>
    </b-row>

    <!-- Association and Un-association buttons -->
    <b-row class="mx-auto">
      <b-col>
        <b-button-group class="w-100">
          <!-- Remove Association -->
          <b-button
            variant="flat-danger"
            :disabled="selectedAssociatedItems.length === 0"
            @click="remove"
          >
            <feather-icon icon="ArrowUpIcon" size="24" />
          </b-button>

          <!-- Add Association -->
          <b-button
            variant="flat-success"
            :disabled="selectedAllItems.length === 0"
            @click="add"
          >
            <feather-icon icon="ArrowDownIcon" size="24" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- Associated Items -->
    <b-row class="mb-2">
      <b-col>
        <label for="associatedItems">
          <b-badge class="bg-danger">{{ associatedItems.length }}</b-badge> :
          Associated Key Results/KPI{{ associatedItems | handlePluralSuffix }}
        </label>
        <div v-if="loadingAssociated" class="text-center">
          <b-spinner class="mt-3" variant="primary" label="Loading..." />
        </div>
        <b-form-select
          v-else
          id="associatedItems"
          v-model="selectedAssociatedItems"
          :options="associatedItems"
          multiple
          :select-size="6"
        />
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="success" :disabled="loadingSubmit" @click="submit">
        <span v-if="loadingSubmit">
          <b-spinner small type="grow" />
          Associating...
        </span>
        <span v-else>Associate Key Results/KPIs</span>
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data: () => ({
    newKPI: '',

    allItems: [],
    selectedAllItems: [],

    associatedItems: [],
    selectedAssociatedItems: [],

    loadingAll: true,
    loadingSubmit: false,
    loadingAssociated: true,
  }),
  computed: {
    ...mapState({
      kpis: state => state.canvas.selectedObjective.kpis,
    }),
  },
  methods: {
    onShow() {
      this.fetchAll()
    },
    create() {
      const payload = { name: this.newKPI }
      this.$store
        .dispatch('canvas/createKPI', payload)
        .then(response => {
          this.associatedItems.push({ value: response, text: payload.name })
          this.fetchAll(false)
        })
        .catch(e => console.error(e))
    },
    fetchAll(showLoading = true) {
      if (showLoading) {
        this.loadingAll = true
      }
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/canvas/kpis', { params })
        .then(({ data }) => {
          this.allItems = data.map(item => ({
            value: item.id,
            text: item.name,
          }))

          this.fetchAssociated()
          // Remove already associated elements from the allItems array
          this.associatedItems.forEach((elementA, indexA) => {
            this.allItems.forEach((elementB, indexB) => {
              if (elementA.value === elementB.value) {
                this.allItems.splice(indexB, 1)
              }
            })
          })

          this.loadingAll = false
          // console.debug('[AssociateObjectivesModal] fetchAll returned:')
          // console.debug(this.allItems)
        })
        .catch(r => {
          this.loadingAll = true
          console.error(`[AssociateObjectivesModal] fetchAll failed - ${r}`)
        })
    },
    fetchAssociated() {
      this.kpis.forEach(KpiId => {
        this.allItems.forEach(element => {
          if (KpiId === element.value) {
            this.associatedItems.push({ value: KpiId, text: element.text })
          }
        })
      })
      this.loadingAssociated = false
      // console.debug('[AssociateObjectivesModal] fetchAssociated returned:')
      // console.debug(this.associatedItems)
    },
    add() {
      this.selectedAllItems.forEach((elementA, indexA) => {
        this.allItems.forEach((elementB, indexB) => {
          if (elementA === elementB.value) {
            this.allItems.splice(indexB, 1)
            this.associatedItems.push(elementB)
          }
        })
      })
      this.selectedAllItems = []
    },
    remove() {
      this.selectedAssociatedItems.forEach((elementA, indexA) => {
        this.associatedItems.forEach((elementB, indexB) => {
          if (elementA === elementB.value) {
            this.associatedItems.splice(indexB, 1)
            this.allItems.push(elementB)
          }
        })
      })
      this.selectedAssociatedItems = []
    },
    clear() {
      this.allItems = []
      this.associatedItems = []
      this.newKPI = ''
    },
    submit(evt) {
      evt.preventDefault()
      const kpis = this.associatedItems.map(kpi => (kpi.value))
      this.$store.commit('canvas/SET_SELECTED_OBJECTIVE_KPIS', kpis)
      this.$emit('resolve')
      this.clear()
      this.$bvModal.hide('associate-kpis-create-modal')
    },
  },
}
</script>
