<template>
  <b-modal
    id="manage-benefits-modal"
    title="Manage Benefits"
    size="xl"
    ok-title="Done"
    ok-variant="outline-success"
    ok-only
    @ok="submit"
    @shown="shown"
  >
    <b-table
      id="tblManageBenefits"
      :items="items"
      :fields="fields"
      responsive="sm"
    >
      <!-- Name -->
      <template v-slot:cell(name)="data">
        <b-form-textarea
          v-if="items[data.index].isEditing"
          v-model="items[data.index].name"
          placeholder="Benefit Label"
        />
        <span v-else>{{ data.value }}</span>
      </template>
      <!-- ./Name -->

      <!-- Description -->
      <template v-slot:cell(description)="data">
        <b-form-textarea
          :value="data.value"
          placeholder="Describe the Benefit in more detail..."
        />
      </template>
      <!-- ./Description -->

      <!-- Action Buttons -->
      <template v-slot:cell(actions)="row">
        <b-button
          v-if="!items[row.index].isEditing"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-secondary"
          size="sm"
          class="btn-icon"
          @click="editRowHandler(row)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-success"
          size="sm"
          class="btn-icon"
          @click="editRowHandler(row)"
        >
          <feather-icon icon="CheckIcon" class="pt-50" />
          Save
        </b-button>
        <b-button
          v-if="!items[row.index].isEditing"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-danger"
          size="sm"
          class="btn-icon"
          @click="deleteRow(row)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>
      <!-- ./Action Buttons -->
    </b-table>
  </b-modal>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ManageBenefits',
  components: {
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Benefit' },
        { key: 'description', label: 'Description' },
        { key: 'actions', label: '' },
      ],
      items: [
        {
          name: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."',
          description: '1234',
        },
        { name: 'B1', description: '1234' },
      ],
    }
  },
  mounted() {
    this.items = this.items.map(item => ({ ...item, isEditing: false }))
  },
  methods: {
    editRowHandler(data) {
      this.items[data.index].isEditing = !this.items[data.index].isEditing
    },
    shown() {
      console.log('shown')
    },
    submit() {
      console.log('submit')
    },
    editRow(row) {
      console.log(row)
    },
    deleteRow(row) {
      console.log(row)
    },
  },
}
</script>

<style lang="scss">
#manage-benefits-modal {
  width: 75%;
  margin-left: 12.5%;
  margin-right: 12.5%;
}

#tblManageBenefits td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>
