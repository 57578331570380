<template>
  <div>
    <b-modal
      id="update-stakeholder-modal"
      title="Update Stakeholder"
      size="xl"
    >
      <b-row>
        <!-- Stakeholder Description -->
        <b-col>
          <b-form-group class="mb-2">
            <label for="name">Stakeholder:</label>
            <b-form-input id="name" v-model="updatedStakeholder.name" type="text" placeholder="Who is the Stakeholder?" required />
          </b-form-group>

          <b-form-group class="mb-2">
            <label for="desc">Detailed Description:</label>
            <b-form-textarea
              id="desc"
              v-model="updatedStakeholder.description"
              placeholder="Describe the Stakeholder..."
              rows="6"
            />
          </b-form-group>

          <b-form-group class="mb-2">
            <label for="concerns">Key Concerns:</label>
            <b-form-textarea
              id="concerns"
              v-model="updatedStakeholder.concerns"
              placeholder="What are their Key Concerns?"
              rows="6"
            />
          </b-form-group>
        </b-col>

        <!-- Stakeholder Analysis -->
        <b-col>
          <h3>Stakeholder Analysis
            <span class="font-medium-2 float-right mt-25">
              <span class="font-medium-1">Classification:</span>
              <span class="text-primary font-weight-bolder ml-75">{{ updatedStakeholder.class }}</span>
            </span>
          </h3>

          <b-row class="mt-2 mx-2">
            <b-col cols="6">
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Level of Decision Making Power"
              >
                <b-form-radio-group
                  id="power"
                  v-model="updatedStakeholder.power"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="power-btn-default"
                  buttons
                  button-variant="outline-primary"
                  @change="updateStakeholderClassification()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Level of Interest / Legitimacy"
              >
                <b-form-radio-group
                  id="interest"
                  v-model="updatedStakeholder.interest"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="interest-btn-default"
                  buttons
                  button-variant="outline-primary"
                  @change="updateStakeholderClassification()"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 mx-2">
            <b-col>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Relevant Views:"
              >
                <b-form-checkbox-group
                  id="views-group"
                  v-model="updatedStakeholder.views"
                  :options="view_opts"
                  :aria-describedby="ariaDescribedby"
                  name="views-1"
                  stacked
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading" @click="editStakeholder">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Updating...
          </span>

          <span v-else>
            Update Stakeholder
          </span>

        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    updatedStakeholder: {
      id: '',
      name: '',
      description: '',
      concerns: '',
      power: 'high',
      interest: 'high',
      class: 'Key Player',
      views: [],
    },
    loading: false,

    // TODO: Pull this stuff from config
    options: [
      { text: 'High', value: 'high' },
      { text: 'Low', value: 'low' },
    ],
    view_opts: [
      { text: 'Acceptance Criteria / Test Cases', value: 'Acceptance Criteria / Test Cases' },
      { text: 'Domain Model', value: 'Domain Model' },
      { text: 'Functional Allocation', value: 'Functional Allocation' },
      { text: 'Functional Decomposition', value: 'Functional Decomposition' },
      { text: 'Interfaces', value: 'Interfaces' },
      { text: 'Requirements', value: 'Requirements' },
      { text: 'Strategic Canvas and Mappings', value: 'Strategic Canvas and Mappings' },
      { text: 'Uncertainty and Technical Risk', value: 'Uncertainty and Technical Risk' },
      { text: 'Verification Results', value: 'Verification Results' },
      { text: 'Workflow / Business Process', value: 'Workflow / Business Process ' },
    ],
  }),
  computed: {
    ...mapState({
      selectedStakeholder: state => state.canvas.selectedStakeholder,
    }),
  },
  watch: {
    default(newVal) { this.value = newVal },
    selectedStakeholder(newVal) {
      const t = this.updatedStakeholder
      t.id = newVal.id
      t.name = newVal.name
      t.description = newVal.description
      t.concerns = newVal.concerns
      t.power = newVal.power
      t.interest = newVal.interest
      t.class = newVal.class
      t.views = newVal.views
    },
  },
  methods: {
    editStakeholder() {
      this.loading = true
      this.$store.dispatch('canvas/updateStakeholder', this.updatedStakeholder).then(() => {
        this.loading = false
        this.$bvModal.hide('update-stakeholder-modal')
      })
    },
    updateStakeholderClassification() {
      const p = this.updatedStakeholder.power
      const i = this.updatedStakeholder.interest
      if (p === 'high' && i === 'high') {
        this.updatedStakeholder.class = 'Key Player'
      } else if (p === 'high' && i === 'low') {
        this.updatedStakeholder.class = 'Keep Satisfied'
      } else if (p === 'low' && i === 'high') {
        this.updatedStakeholder.class = 'Keep Informed'
      } else {
        this.updatedStakeholder.class = 'Minimal Effort'
      }
    },
  },
}
</script>

<style lang="scss">
#edit-stakeholder-modal {
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

#views-group > .custom-checkbox {
  margin-top: 0.3rem;
}
</style>
