<template>
  <div>
    <b-modal
      id="create-stakeholder-modal"
      title="Create Stakeholder"
      size="xl"
      @hide="clear"
    >
      <b-row>
        <!-- Stakeholder Description -->
        <b-col>
          <b-form-group class="mb-2">
            <label for="name">Stakeholder</label>
            <b-form-input id="name" v-model="newStakeholder.name" type="text" placeholder="Who is the Stakeholder?" required />
          </b-form-group>

          <b-form-group class="mb-2">
            <label for="desc">Description</label>
            <b-form-textarea
              id="desc"
              v-model="newStakeholder.description"
              placeholder="Describe the Stakeholder..."
              rows="6"
            />
          </b-form-group>

          <b-form-group class="mb-2">
            <label for="concerns">Key Concerns</label>
            <b-form-textarea
              id="concerns"
              v-model="newStakeholder.concerns"
              placeholder="What are their Key Concerns?"
              rows="6"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <h3>Stakeholder Analysis
            <span class="font-medium-2 float-right mt-25">
              <span class="font-medium-1">Classification:</span>
              <span class="text-primary font-weight-bolder ml-75">{{ newStakeholder.class }}</span>
            </span>
          </h3>

          <b-row class="mt-2 mx-2">
            <b-col cols="6">
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Level of Decision Making Power"
              >
                <b-form-radio-group
                  id="power"
                  v-model="newStakeholder.power"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="power-btn-default"
                  buttons
                  button-variant="outline-primary"
                  @change="resolveStakeholderClassification()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Level of Interest / Legitimacy"
              >
                <b-form-radio-group
                  id="interest"
                  v-model="newStakeholder.interest"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="interest-btn-default"
                  buttons
                  button-variant="outline-primary"
                  @change="resolveStakeholderClassification()"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 mx-2">
            <b-col>
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Relevant Views"
              >
                <b-form-checkbox-group
                  id="views-group"
                  v-model="newStakeholder.views"
                  :options="view_opts"
                  :aria-describedby="ariaDescribedby"
                  name="views-1"
                  stacked
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading" @click="submit">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Creating...
          </span>
          <span v-else>
            Create Stakeholder
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    newStakeholder: {
      name: '',
      description: '',
      concerns: '',
      power: 'high',
      interest: 'high',
      class: 'Key Player',
      views: [],
    },

    // TODO: Pull this stuff from config
    options: [
      { text: 'High', value: 'high' },
      { text: 'Low', value: 'low' },
    ],
    view_opts: [
      { text: 'Acceptance Criteria / Test Cases', value: 'Acceptance Criteria / Test Cases' },
      { text: 'Domain Model', value: 'Domain Model' },
      { text: 'Functional Allocation', value: 'Functional Allocation' },
      { text: 'Functional Decomposition', value: 'Functional Decomposition' },
      { text: 'Interfaces', value: 'Interfaces' },
      { text: 'Requirements', value: 'Requirements' },
      { text: 'Strategic Canvas and Mappings', value: 'Strategic Canvas and Mappings' },
      { text: 'Uncertainty and Technical Risk', value: 'Uncertainty and Technical Risk' },
      { text: 'Verification Results', value: 'Verification Results' },
      { text: 'Workflow / Business Process', value: 'Workflow / Business Process ' },
    ],
  }),
  methods: {
    submit() {
      this.loading = true
      this.$store.dispatch('canvas/createStakeholder', this.newStakeholder).then(() => {
        this.loading = false
        this.$bvModal.hide('create-stakeholder-modal')
      })
    },
    resolveStakeholderClassification() {
      const p = this.newStakeholder.power.toLowerCase()
      const i = this.newStakeholder.interest.toLowerCase()
      if (p === 'high' && i === 'high') {
        this.newStakeholder.class = 'Key Player'
      } else if (p === 'high' && i === 'low') {
        this.newStakeholder.class = 'Keep Satisfied'
      } else if (p === 'low' && i === 'high') {
        this.newStakeholder.class = 'Keep Informed'
      } else {
        this.newStakeholder.class = 'Minimal Effort'
      }
    },
    clear() {
      this.loading = false
      this.newStakeholder = {
        name: '',
        description: '',
        concerns: '',
        power: 'high',
        interest: 'high',
        class: 'Key Player',
        views: [],
      }
    },
  },
}
</script>

<style lang="scss">
#create-stakeholder-modal {
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}
</style>
