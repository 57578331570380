<template>
  <b-modal
    id="delete-objective-modal"
    title="Delete Objective. Are you sure?"
    size="md"
    centered
    @ok="submit"
  >
    <p>
      Deleting the
      <span class="text-primary font-weight-bolder">{{ selectedObjective.name }}</span>
      objective cannot be undone.
    </p>
    <p class="mb-0 font-weight-bolder">
      Are you sure?
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="ok()">
        Yes, delete objective
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectedObjective: state => state.canvas.selectedObjective,
    }),
  },
  methods: {
    submit(event) {
      event.preventDefault()
      this.$store
        .dispatch('canvas/deleteObjective', this.selectedObjective.id)
        .then(() => {
          this.$store.commit('canvas/CLEAR_SELECTED_OBJECTIVE')
          this.$bvModal.hide('delete-objective-modal')
        })
    },
  },
}
</script>
