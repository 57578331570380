<template>
  <div>
    <b-modal
      id="create-constraint-modal"
      title="Create Constraint"
      size="lg"
      @hide="clear"
    >
      <b-row>
        <b-col>
          <label for="name">Title</label>
          <b-form-input
            id="name"
            v-model="newConstraint.name"
            type="text"
            placeholder="What is the constraint?"
            required
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <label for="desc">Description</label>
          <b-form-textarea
            id="desc"
            v-model="newConstraint.description"
            placeholder="Describe the details of the constraint..."
            rows="5"
          />
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading" @click="submit">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Creating...
          </span>

          <span v-else>
            Create Constraint
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    newConstraint: {
      name: '',
      description: '',
    },
  }),
  methods: {
    submit() {
      this.loading = true
      this.$store.dispatch('canvas/createConstraint', this.newConstraint).then(() => {
        this.loading = false
        this.$bvModal.hide('create-constraint-modal')
      })
    },
    clear() {
      this.loading = false
      this.newConstraint = {
        name: '',
        description: '',
      }
    },
  },
}
</script>
