<template>
  <div>
    <b-modal
      id="update-objective-modal"
      title="Update Objective"
      size="xl"
      @show="show"
      @hide="hide"
    >
      <b-row>
        <b-col>
          <b-row class="mb-2">
            <b-col>
              <label for="brs_obj_name">Objective</label>
              <b-form-textarea
                id="brs_obj_name"
                v-model="localObjective.name"
                placeholder="What is the objective?"
                rows="5"
              />
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <label for="brs_obj_desc">Detailed Description</label>
              <b-form-textarea
                id="brs_obj_desc"
                v-model="localObjective.description"
                placeholder="Describe the Objective or Outcome..."
                rows="6"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <!-- Associated Benefits -->
          <div class="mb-2">
            <b-button
              v-b-tooltip.hover.left="'Update associated Benefits'"
              variant="flat-success"
              size="sm"
              class="mb-25"
              @click="$bvModal.show('associate-benefits-modal')"
            >
              <feather-icon icon="LinkIcon" />
            </b-button>
            <span class="font-medium-2">Associated Benefits</span>

            <b-table striped hover :items="tblDataBenefits" :fields="tblFieldsBenefits">
              <template v-slot:cell(benefit)="data">
                <div v-if="data.item.description && data.item.description !== ''">
                  <b>{{ data.item.name }}</b><br>
                  {{ data.item.description }}
                </div>
                <div v-else>
                  {{ data.item.name }}
                </div>
              </template>
            </b-table>
          </div>

          <!-- Associated KPIs -->
          <div class="mb-2">
            <b-button
              v-b-tooltip.hover.left="'Update associated Key Results/KPIs'"
              variant="flat-success"
              size="sm"
              class="mb-25"
              @click="$bvModal.show('associate-kpis-modal')"
            >
              <feather-icon icon="LinkIcon" />
            </b-button>
            <span class="font-medium-2">Associated Key Results/KPIs</span>

            <b-table striped hover :items="tblDataKPIs" :fields="tblFieldsKPIs">
              <template v-slot:cell(kpi)="data">
                <div v-if="data.item.description && data.item.description !== ''">
                  <b>{{ data.item.name }}</b><br>
                  {{ data.item.description }}
                </div>
                <div v-else>
                  {{ data.item.name }}
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <!-- Modal Buttons -->
      <template v-slot:modal-footer="{ edit, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading" @click="submit">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Updating...
          </span>

          <span v-else>
            Update Objective
          </span>
        </b-button>
      </template>
    </b-modal>

    <!-- Association Modals -->
    <AssociateBenefits @resolve="resolveBenefits" />
    <AssociateKPIs @resolve="resolveKPIs" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BButton, VBTooltip } from 'bootstrap-vue'
import AssociateBenefits from '@/components/BRSCanvas/Modals/AssociateBenefits.vue'
import AssociateKPIs from '@/components/BRSCanvas/Modals/AssociateKPIs.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    AssociateBenefits,
    AssociateKPIs,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    localObjective: {
      name: '',
      description: '',
    },
    loading: false,
    tblFieldsBenefits: [
      {
        key: 'name',
        label: 'Benefit',
        sortable: true,
      },
    ],
    tblFieldsKPIs: [
      {
        key: 'name',
        label: 'KPI',
        sortable: true,
      },
    ],
    tblDataBenefits: [],
    tblDataKPIs: [],
  }),
  computed: {
    ...mapState({
      selectedObjective: state => state.canvas.selectedObjective,
      benefits: state => state.canvas.benefits,
      kpis: state => state.canvas.kpis,
    }),
  },
  methods: {
    show() {
      this.localObjective = this.selectedObjective
      this.$store
        .dispatch('canvas/fetchBenefits')
        .then(() => this.resolveBenefits())
      this.$store
        .dispatch('canvas/fetchKPIs')
        .then(() => this.resolveKPIs())
    },
    hide() {
      this.$store.commit('canvas/CLEAR_SELECTED_OBJECTIVE')
    },
    submit() {
      this.loading = true
      this.$store.dispatch('canvas/updateObjective', this.selectedObjective)
        .then(() => {
          this.loading = false
          this.$bvModal.hide('update-objective-modal')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Objective updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
    resolveBenefits() {
      this.$store
        .dispatch('canvas/fetchBenefits')
        .then(() => {
          this.tblDataBenefits = []
          this.selectedObjective.benefits.forEach(elementA => {
            this.benefits.forEach(elementB => {
              if (elementA === elementB.id) {
                this.tblDataBenefits.push({ id: elementA, name: elementB.name })
              }
            })
          })
        })
    },
    resolveKPIs() {
      this.$store
        .dispatch('canvas/fetchKPIs')
        .then(() => {
          this.tblDataKPIs = []
          this.localObjective.kpis.forEach(elementA => {
            this.kpis.forEach(elementB => {
              if (elementA === elementB.id) {
                this.tblDataKPIs.push({ id: elementA, name: elementB.name })
              }
            })
          })
        })
    },
  },
}
</script>

<style lang="scss">
#update-objective-modal {
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}
</style>
