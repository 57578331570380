<template>
  <div>
    <b-modal
      id="create-assumption-modal"
      title="Create Assumption"
      size="lg"
      @hide="clear"
    >
      <b-row class="mb-2">
        <b-col>
          <label for="add_assumption_name">Title</label>
          <b-form-input
            id="add_assumption_name"
            v-model="newAssumption.name"
            type="text"
            placeholder="What is the assumption?"
            required
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <label for="add_assumption_desc">Description</label>
          <b-form-textarea
            id="add_assumption_desc"
            v-model="newAssumption.description"
            placeholder="Describe the details of the assumption..."
            rows="6"
          />
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading" @click="submit">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Creating...
          </span>

          <span v-else>
            Create Assumption
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    newAssumption: {
      name: '',
      description: '',
    },
  }),
  methods: {
    submit() {
      this.loading = true
      this.$store
        .dispatch('canvas/createAssumption', this.newAssumption)
        .then(() => {
          this.loading = false
          this.$bvModal.hide('create-assumption-modal')
        })
    },
    clear() {
      this.loading = false
      this.newAssumption = {
        name: '',
        description: '',
      }
    },
  },
}
</script>
