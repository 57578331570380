<template>
  <div>
    <b-modal
      id="update-constraint-modal"
      title="Update Project Constraint"
      size="lg"
    >
      <b-row class="mb-2">
        <b-col>
          <label for="name">Constraint:</label>
          <b-form-input
            id="name"
            v-model="sub_obj.name"
            placeholder="What is the constraint?"
            required
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <label for="desc">Detailed Description:</label>
          <b-form-textarea
            id="desc"
            v-model="sub_obj.description"
            placeholder="Describe the constraint..."
            rows="6"
          />
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ edit, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading_status_add" @click="editProjectConstraint">
          <span v-if="loading_status_add">
            <b-spinner small type="grow" />
            Updating...
          </span>

          <span v-else>
            Update Constraint
          </span>

        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },
  data: () => ({
    sub_obj: {
      id: '',
      name: '',
      description: '',
    },
    loading_status_add: false,
  }),
  computed: {
    ...mapState({
      constraints: state => state.canvas.constraints,
      selectedConstraint: state => state.canvas.selectedConstraint,
    }),
  },
  watch: {
    default(newVal) { this.value = newVal },
    selectedConstraint(newVal) {
      const t = this.sub_obj
      t.id = newVal.id
      t.name = newVal.name
      t.description = newVal.description
    },
  },
  mounted() {
  },
  methods: {
    editProjectConstraint() {
      this.loading_status_add = true
      this.$store.dispatch('canvas/updateConstraint', this.sub_obj).then(() => {
        this.loading_status_add = false
        this.$bvModal.hide('update-constraint-modal')
      })
    },
  },
}
</script>
