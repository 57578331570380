<template>
  <b-modal
    id="delete-constraint-modal"
    title="Delete Constraint. Are you sure?"
    size="md"
    centered
    @ok="submit"
  >
    <p>
      Deleting the
      <span class="text-primary font-weight-bolder">{{ selectedConstraint.name }}</span>
      constraint cannot be undone.
    </p>
    <p class="mb-0 font-weight-bolder">
      Are you sure?
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="ok()">
        Yes, delete constraint
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectedConstraint: state => state.canvas.selectedConstraint,
    }),
  },
  methods: {
    submit(event) {
      event.preventDefault()
      this.$store
        .dispatch('canvas/deleteConstraint', this.selectedConstraint.id)
        .then(() => {
          this.$store.commit('canvas/CLEAR_SELECTED_CONSTRAINT')
          this.$bvModal.hide('delete-constraint-modal')
        })
    },
  },
}
</script>
